import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";

function SideBar() {
  const location = useLocation();
  const Navigate = useNavigate();
  const [openMenu,setOpenMenu] = useState(false)

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    Navigate("/login");
  };

  return (
    <div className={openMenu ? "flex h-[100vh] w-[20%] duration-200 flex-col justify-between border-e bg-[#05314B] overflow-hidden":"flex h-[100vh] duration-200 w-[5%] flex-col justify-between border-e bg-[#05314B] overflow-hidden"}>
      <div className="px-4 py-6">
       <i onClick={()=>{setOpenMenu(!openMenu)}} className="text-2xl text-white font-bold"> <IoMenu /> </i>

        {/* <span className="grid h-10 w-32 place-content-center rounded-lg bg-[] text-xs text-gray-600">
          <img src="" alt="" srcset="" />
        </span> */}
       {openMenu && <ul className="mt-6 space-y-1">
          <li>
            <Link
              to="/"
              className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
                location.pathname === "/" ? "bg-[#0FCFFF]" : ""
              }`}
            >
              Dashboard
            </Link>
          </li>

          <li>
            <Link
              to="/upload-excel"
              className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
                location.pathname === "/upload-excel" ? "bg-[#0FCFFF]" : ""
              }`}
            >
              Upload Excel
            </Link>
          </li>

          <li>
            <Link
              to="/fatch-excel-data"
              className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
                location.pathname === "/fatch-excel-data" ? "bg-[#0FCFFF]" : ""
              }`}
            >
              Fatch Excel
            </Link>
          </li>
        </ul>}
      </div>
      {openMenu &&<div className="sticky inset-x-0 bottom-6 border-t border-#0FCFFF">
        <Link
          to="/login"
          onClick={handleLogout}
          className={`block rounded-lg px-4 py-2 text-sm font-medium text-red-300 ${
            location.pathname === "/login" ? "bg-[#0FCFFF]" : ""
          }`}
        >
          Logout
        </Link>
      </div>}
    </div>
  );
}

export default SideBar;
