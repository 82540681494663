import React from 'react'
import Enquiry from './Enquiry'


const Dashboard = () => {
  return (
    <>
      <Enquiry/>
    </>
  )
}

export default Dashboard
