import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import axiosInstance from "../axiosInstance/AxiosInstance";
import toast from "react-hot-toast";
import AddEnquiryForm from "../components/AddEnquiryForm";
import { MdOutlineFileUpload } from "react-icons/md";
import { BsFiletypeXls } from "react-icons/bs";

const UploadExcel = () => {
  const [data, setData] = useState([]);
  const [selectedXlsFile, setSelectedXlsFile] = "select file";
  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setData(jsonData);
        document.getElementById("uploadfile_placeholder").innerHTML = file.name;
        console.log(jsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleSave = async () => {
    try {
      await axiosInstance.post("/excel/save", { data });
      console.log(data);
      toast.success("Data upload successfully");
      // Reset the input field
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      alert(
        `Error: ${error.response ? error.response.data.message : error.message}`
      );
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-screen overflow-auto">
      <div className=" w-4/5 py-4  bg-[#05314B] rounded-sm shadow-lg">
        <h1 className="text-center  text-2xl text-white font-bold">
          Upload data
        </h1>
        <div className="flex items-center gap-5  flex-row justify-center bg-grey-lighter mt-4">
          <div className="relative cursor-pointer w-2/5 h-2/5 border-dashed  border-2 border-white rounded-lg overflow-hidden" onDragCapture={(e)=>{console.log(e)}}>
            <label className=" flex flex-col justify-center items-center px-4 py-6 bg-white w-full text-blue rounded-lg shadow-lg  border border-blue cursor-pointer hover:bg-blue-900 transition hover:text-white">
              <span
                id="uploadfile_placeholder"
                className="text-xl flex flex-row gap-2 items-center font-semibold text-gray-400"
              >
                <i className="text-4xl">
                  <BsFiletypeXls />
                </i>{" "}
                <p>Upload xls file</p>
              </span>
            </label>
            <input
              className="cursor-pointer opacity-0 absolute top-0 w-full h-full"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>
          <div
            className="bg-blue-900 rounded-full px-4 py-4 text-white  font-bold shadow-lg cursor-pointer hover:bg-slate-100 transition hover:border-2 border-spacing-y-9 hover:text-blue-900"
            onClick={handleSave}
          >
            <i className="text-4xl">
              <MdOutlineFileUpload />
            </i>
          </div>
        </div>
      </div>
      <div className="bg-[#05314B] w-4/5 shadow-xl rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
        <h1 className="text-center  text-2xl text-white font-bold">
          Add New enquiry Data
        </h1>
        <AddEnquiryForm />
      </div>
    </div>
  );
};

export default UploadExcel;
