import React from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "../axiosInstance/AxiosInstance";

const AddEnquiryForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const addNewEnquiry = async (data) => {
    try {
      const addForm = await axiosInstance.post("/excel/add",data);
      alert("new enquiry data added");
      reset();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <form className="my-8 " onSubmit={handleSubmit(addNewEnquiry)}>
      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-firstname"
          >
            FULL NAME
          </label>
          <input
            className="appearance-none block w-full bg-white text-black border-black border border-red rounded py-3 px-4 mb-3"
            {...register("Full_Name", { required: "name is required!" })}
            id="grid-fullname"
            type="text"
            placeholder="Jane"
          />
        </div>
        <div className="md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-number"
          >
            NUMBER
          </label>
          <input
            {...register("Number", { required: true })}
            className="appearance-none block w-full bg-white text-black border-black border border-grey-lighter rounded py-3 px-4"
            id="grid-number"
            type="tel"
            placeholder="Enter contact number"
          />
        </div>
        <div className="md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-last-name"
          >
            ALT NUMBER
          </label>
          <input
            className="appearance-none block w-full bg-white text-black border-black border border-grey-lighter rounded py-3 px-4"
            id="grid-last-name"
            {...register("Alt_Number")}
            type="tel"
            placeholder="Enter contact number"
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-6">
        <div className="md:w-full px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-class"
          >
            CLASS
          </label>
          <select
            {...register("Class", { required: "class is required" })}
            id="grid-class"
            className="appearance-none block w-full  bg-white text-black border-black border border-grey-lighter rounded py-3 px-4 mb-3"
          >
            <option>Select</option>
            <option value="12th"> 11th</option>
            <option value="11th">12th</option>
            <option value="Demo">Demo</option>
          </select>
        </div>
        <div className="md:w-full px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-course"
          >
            COURSE
          </label>
          <select
            {...register("Course", { required: "course is required" })}
            id="grid-course"
            className="appearance-none block w-full bg-white text-black border border-black rounded py-3 px-4 mb-3"
          >
            <option>Select</option>
            <option value="NEET"> NEET</option>
            <option value="JEE">JEE(Mains)</option>
            <option value="JEE(Mains + Advance)">JEE(Mains + Advance)</option>
          </select>
        </div>
        <div className="md:w-full px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-city"
          >
            City
          </label>
          <input
            {...register("City", { required: "city is required" })}
            className="appearance-none block w-full  bg-white text-black border-black border border-grey-lighter rounded py-3 px-4"
            id="grid-city"
            type="text"
            placeholder="Enter City"
          />
        </div>
      </div>
      <div className="-mx-3 md:flex mb-2">
        <div className="md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-state"
          >
            MESSAGE
          </label>
          <div className="relative">
            <textarea
              {...register("Message")}
              className="appearance-none block w-full  bg-white text-black border-black border border-grey-lighter rounded py-3 px-4"
              id="grid-city"
              type="textbox"
              placeholder="enter message"
            />
          </div>
        </div>
      </div>
      <button className="bg-black text-white rounded-md text-sm hover:bg-gray-800 cursor-pointer font-normal">
        Add Enquiry
      </button>
    </form>
  );
};

export default AddEnquiryForm;
