import React from "react";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaEye, FaSave } from "react-icons/fa";
import axiosInstance from "../axiosInstance/AxiosInstance";
import toast from "react-hot-toast";

const Enquiry = () => {
  const tableRef = useRef(null);
  const [defaultEnquiryData, setDefaultEnqueryData] = useState([]);
  const dateRef = useRef(new Date());
  const [enquiryData, setEnquiryData] = useState([]);
  const [searchCity, setSearchCity] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const paginatedEnquiry = enquiryData.slice(start, end);
  const [filterOn, setFilerOn] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [showFilterForm1, setShowFilterForm1] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/enquiry/get");
      setEnquiryData(response.data);
      setDefaultEnqueryData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleNext = () => {
    if (end < enquiryData.length) {
      setStart(end);
      setEnd(end + 10);
    }
  };
  //=================== reset to default ======================
  const resetDataToDefault = () => {
    fetchData();
  };
  //=================handle filter by date==============
  const handleFilterByDate = (filterDate) => {
    let data = enquiryData.filter((item) => {
      item.updatedAt = moment(item.updatedAt).format("yyyy-MM-DD");
      // console.log(item)
      return item.updatedAt === filterDate;
    });
    setEnquiryData(data);
    setFilerOn("all");
  };
  const handleFilterByCity = (filterCity) => {
    console.log(filterCity);
    let data = enquiryData.filter((item) => {
      return item.city.includes(filterCity);
    });
    setEnquiryData(data);
    setFilerOn("all");
  };

  const handlePrevious = () => {
    if (start > 0) {
      setEnd(start);
      setStart(start - 10);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(enquiryData);


  const handleSaveFollowUp = async (id, message) => {
    try {
      await axiosInstance.put(`/enquiry/update/${id}`, { message });
      fetchData(); // Refresh data after update
      toast.success("Follow up message Added")
    } catch (error) {
      console.error("Error saving follow-up:", error.message);
      toast.error("Please try again")
    }
  };

  return (
    <>
      {/* <Header/> */}
      <div className="flex flex-col w-full h-[100vh] overflow-hidden">
        <div className="bg-blue-800 flex items-center justify-center  text-white font-bold text-center text-2xl py-4">
          <h1 className="w-fit">Student Enquiries</h1>
        </div>
        <div className="flex item-center px-3 gap-2 py-2">
          <div
            onClick={() => {
              setFilerOn("all");
            }}
            className="cursor-pointer duration-200 hover:bg-gray-700 font-xl hover:scale-90 font-semibold bg-black text-white py-2 px-3 w-fit rounded-md flex items-center justify-center gap-2"
          >
            <FaEye /> View All Data{" "}
          </div>
          <DownloadTableExcel
            filename={`Resonance Leads ${moment().format("MMM Do YY")}`}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <div className="cursor-pointer hover:bg-gray-700 duration-200 hover:scale-90 font-xl font-semibold bg-black text-white py-2 px-3 w-fit rounded-md flex items-center justify-center gap-2">
              <SiMicrosoftexcel></SiMicrosoftexcel> Export excel{" "}
            </div>
          </DownloadTableExcel>

          {showFilterForm && (
            <form className="flex gap-1">
              <input
                ref={dateRef}
                className="bg-gray-600 text-white rounded-lg py-2 px-3"
                type="date"
                name="date"
                id="date"
              />
            </form>
          )}
          <div
            onClick={() => {
              setShowFilterForm(!showFilterForm);
            }}
            className="cursor-pointer hover:bg-gray-700 duration-200 hover:scale-90 font-xl font-semibold bg-black text-white py-2 px-3 w-fit rounded-md flex items-center justify-center gap-2"
          >
            {!showFilterForm ? (
              <div
                onClick={() => {
                  resetDataToDefault();
                }}
              >
                Filter By Date
              </div>
            ) : (
              <div
                onClick={() => {
                  handleFilterByDate(dateRef.current.value);
                }}
              >
                Get Data
              </div>
            )}
          </div>

          {filterOn !== false && (
            <div
              onClick={() => {
                setFilerOn(false);
                resetDataToDefault();
              }}
              className="cursor-pointer hover:bg-red-300 duration-200 hover:scale-90 font-xl font-semibold bg-red-600 text-white py-2 px-3 w-fit rounded-md flex items-center justify-center gap-2"
            >
              {" "}
              Clear Filter{" "}
            </div>
          )}
          <div className="px-3 flex items-center bg-green-600 text-white rounded-xl">
            {" "}
            <p>
              {" "}
              <strong>Total Enquiries : </strong> {defaultEnquiryData?.length}
            </p>
          </div>
        </div>
        <div className="flex flex-col ms-2 w-full overflow-x-scroll">
          <table ref={tableRef} className="divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  SNO
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Full_Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Alt_Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Course
                </th>
                <th
                  scope="col"
                  className="px-6 flex items-center gap-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div>City</div>
                  {showFilterForm1 && (
                    <form className="flex gap-1">
                      <select
                        onChange={(e) => setSearchCity(e.target.value)}
                        className="border py-1 px-2 border-gray-400 rounded-sm "
                      >
                        <option>-select-</option>
                        <option value="Other">Other</option>
                        <option value="Indore">Indore</option>
                        <option value="Ujjain">Ujjain</option>
                        <option value="Bhopal">Bhopal</option>
                        <option value="Dewas">Dewas</option>
                        <option value="Khargone">Khargone</option>
                        <option value="Barwaha">Barwaha</option>
                        <option value="Dhar">Dhar</option>
                        <option value="Dhamnod">Dhamnod</option>
                        <option value="Maheshwar">Maheshwar</option>
                        <option value="Sanwer">Sanwer</option>
                        <option value="Depalpur">Depalpur</option>
                        <option value="Hatod">Hatod</option>
                        <option value="Betma">Betma</option>
                        <option value="Manpur">Manpur</option>
                        <option value="Sanawad">Sanawad</option>
                        <option value="Vadodara">Vadodara</option>
                        <option value="Jabalpur">Jabalpur</option>
                        <option value="Sagar">Sagar</option>
                        <option value="Balaghat">Balaghat</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Pune">Pune</option>
                      </select>
                    </form>
                  )}
                  <div className="cursor-pointer border border-red-900 hover:bg-gray-700 hover:text-white duration-200 hover:scale-90 font-xl font-semibold text-black w-fit rounded-md flex items-center justify-center gap-2">
                    {!showFilterForm1 ? (
                      <div
                        className="h-[100%] w-[100%] py-1 px-3"
                        onClick={() => {
                          resetDataToDefault();
                          setShowFilterForm1(!showFilterForm1);
                        }}
                      >
                        ⇓
                      </div>
                    ) : (
                      <div
                        className="h-[100%] w-[100%] py-1 px-3"
                        onClick={() => {
                          handleFilterByCity(searchCity);
                          setShowFilterForm1(!showFilterForm1);
                        }}
                      >
                        filter
                      </div>
                    )}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Class
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Message
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {(filterOn === "all" ? enquiryData : paginatedEnquiry).map(
                (data, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {start + index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.fullname}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.number}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.altnumber}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.course}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{data?.city}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{data?.class}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 flex items-center gap-2">
                        {/* {data?.message} */}
                        <input
                          type="text"
                          className="border-4 p-1 rounded"
                          placeholder="followup-message"
                          defaultValue={data?.message || ""}
                          onChange={(e) => {
                            const newData = [...enquiryData];
                            newData[index].message = e.target.value;
                            setEnquiryData(newData);
                          }}
                        />
                        <FaSave
                          className="text-2xl cursor-pointer"
                          onClick={() =>
                            handleSaveFollowUp(data._id, data.message)
                          }
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {moment(`${data?.createdAt}`).format("Do MMMM YYYY")}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {filterOn !== "all" && (
          <div className="flex p-5 w-full justify-evenly">
            <button
              onClick={handlePrevious}
              disabled={start === 0}
              className="px-4 py-2 bg-[#070616] text-md cursor-pointer rounded-lg"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={end >= enquiryData.length}
              className="px-4 py-2 bg-[#070616] text-md cursor-pointer rounded-lg"
            >
              Next
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Enquiry;
