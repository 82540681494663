import React from "react";

// import homePageMainImage from "../Assets/Images/homePageMainImage.png";
import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar";

const Layout = () => {
  return (
    <>
    <div className="flex w-[100vw] overflow-hidden">
      <SideBar />
      <Outlet />
      </div>
    </>
  );
};

export default Layout;
