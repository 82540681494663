import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ProtectedRoute from "./Layout/ProtectedRoute";
import './App.css'
import UploadExcel from "./pages/UploadExcel";
import FatchExcelData from "./pages/FatchExcelData";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route index element={<Dashboard />} />
          <Route path="/upload-excel" element={<UploadExcel />} />
          <Route path="/fatch-excel-data" element={<FatchExcelData />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
