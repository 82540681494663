import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance/AxiosInstance";
import { useTable, usePagination } from "react-table";
import moment from "moment";

const FatchExcelData = () => {
  const [data, setData] = useState([]);

  const [startPage,setStartPage] = useState(0)
  const [endPage,setEndPage] = useState(startPage+10)
  const paginatedData = data?.slice(startPage,endPage)
  console.log(paginatedData)

  const fatchData = async () => {
    try {
      const response = await axiosInstance.get("/excel/data");
      console.log(response.data)
      setData(response.data)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNextPage = ()=>{
    setStartPage(startPage+10)
    setEndPage(endPage+10)
  }
  const handlePrevPage = () =>{
    setStartPage(startPage-10)
    setEndPage(endPage-10)
  }

  useEffect(() => {
    fatchData();
  }, []);

  return (
    <div className="w-[100%] h-[100vh] overflow-auto">
      <div className="bg-blue-800 flex items-center justify-center  text-white font-bold text-center text-2xl py-2">
        <h1 className="w-fit">Excel Data</h1>
      </div>
      <div>
        <div className="flex flex-col ms-2 w-full">
          <table className="w-[100%] divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  SNO
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Full_Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Alt_Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Course
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  City
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Class
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Message
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {
                paginatedData?.map((data,index)=>(
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                      {startPage + index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.Full_Name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.Number}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.Alt_Number}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {data?.Course}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{data?.City}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{data?.Class}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{data?.Message}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {moment(`${data?.createdAt}`).format("Do MMMM YYYY")}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="flex p-5 w-full justify-evenly">
          <button onClick={()=>{handlePrevPage()}}   disabled={startPage === 0} className="px-5 py-2 bg-[#070616] text-[15px] cursor-pointer rounded-lg">
            Previous
          </button>
          {/* <span className="border-2 rounded-lg bg-slate-200 px-5 py-3">
            Page
            <strong>{pageIndex + 1}</strong>
          </span> */}
          <button onClick={()=>{handleNextPage()}}   disabled={endPage >= data.length} className="px-5 py-2 bg-[#070616] text-[15px] cursor-pointer rounded-lg">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FatchExcelData;
